<script>

export default {
  name: "PlayerRoleBadge",
  props: {
    role: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>

<template>
  <transition appear name="scale-up" mode="out-in">
    <div class="area_state" :class="{
      dealer: role === 'd',
      blind_game: role === 'sb' || role === 'bb' || role === 'pb',
    }">{{ role }}
    </div>
  </transition>
</template>

<style scoped lang="scss">
.scale-up-enter-active {
  position: absolute;
  transition: all .3s ease-in-out;
}

.scale-up-enter, .scale-up-appear /* .fade-leave-active in <2.1.8 */
{
  position: absolute;
  transform-origin: center;
  transform: scale(2);
}
</style>